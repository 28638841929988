import { FC } from 'react';

import Container from 'quarks/Container';
import Flex from 'quarks/Flex';
import Heading from 'quarks/Heading';

import StructuredTextParser from 'molecules/StructuredTextParser/StructuredTextParser';

import Author from 'components/Blog/Author';
import BlogSubscribeForm from 'components/ComponentForm/BlogSubscribeForm';

import type { DatoCmsEntityPerson, DatoCmsTemplateBlog, Maybe } from 'graphqlTypes';

type BlogDetailProps = Maybe<DatoCmsTemplateBlog>;

const BlogDetail: FC<BlogDetailProps> = ({ body, author, publishDate, showSubscribeForm }) => (
  <Flex flexDirection="column-reverse" gap="40px" lg={{ flexDirection: 'row' }}>
    <Flex width="100%" lg={showSubscribeForm || author ? { width: '66.67%', flex: '0 0 66.67%' } : {}}>
      <Container lg={{ flexGrow: 1, flexBasis: 0 }}>
        {body && <StructuredTextParser text={body} textStyle="lg" textColor="gray-600" />}
      </Container>
    </Flex>
    {(showSubscribeForm || author) && (
      <Container lg={{ flexGrow: 1, flexBasis: 0 }}>
        {showSubscribeForm && <BlogSubscribeForm />}
        {author && (
          <Container marginTop={32}>
            <Heading
              as="h5"
              fontSize="textLg"
              lineHeight="textLg"
              fontWeight="semiBold"
              marginBottom={24}
              textColor="gray-900"
            >
              Written by
            </Heading>
            <Author publishDate={publishDate} {...(author as DatoCmsEntityPerson)} />
            <Container textColor="gray-400" fontSize="textSm" marginTop={16}></Container>
          </Container>
        )}
      </Container>
    )}
  </Flex>
);

export default BlogDetail;
