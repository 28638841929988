import { render as toPlainText } from 'datocms-structured-text-to-plain-text';

import Container from 'quarks/Container';
import Flex from 'quarks/Flex';
import Heading from 'quarks/Heading';
import Image, { ImageData } from 'quarks/Image';
import Text from 'quarks/Text';

import { getReadingTime } from 'utils/functions';

import type { DatoCmsTemplateBlog } from 'graphqlTypes';
import type { FC } from 'react';

type BlogHeroProps = DatoCmsTemplateBlog;

const BlogHero: FC<BlogHeroProps> = ({ title, featuredImage, excerpt, body, publishDate }) => {
  const blogExcerpt = excerpt || (body && toPlainText(body.value)?.substring(0, 97) + '...');
  const readingTime = (body?.value && getReadingTime(toPlainText(body.value) as string)) || '';

  return (
    <Flex paddingTop={128} flexDirection="column">
      <Container
        width="100%"
        height="250px"
        overflowX="hidden"
        overflowY="hidden"
        position="absolute"
        bottom={0}
        left={0}
        md={{ height: '300px' }}
        lg={{ height: '400px' }}
        xl={{ height: '500px' }}
        backgroundColor="secondaryGrayBlue-25"
      >
        <Container
          width="150%"
          height="300px"
          position="absolute"
          top="-210px"
          left="50%"
          transform="translateX(-50%)"
          backgroundColor="secondaryGrayBlue-900"
          borderRadius="50%"
          css={{
            boxShadow: '0px 0px 16px #3DB5E6',
          }}
        />
      </Container>
      <Container width="100%" md={{ maxWidth: '1008px', marginX: 'auto' }}>
        <Flex flexDirection="column" gap="24px" textAlign="center" marginBottom={48} lg={{ marginBottom: 64 }}>
          <Heading as="h6" fontSize="textMd" textColor="secondaryTurqoise-400" fontWeight="semiBold">
            {title}
          </Heading>
          <Heading as="h1" textStyle="lg" textColor="common-white" fontWeight="semiBold">
            {title}
          </Heading>
          <Container textColor="gray-50" fontSize="textMd">
            {excerpt || blogExcerpt}
          </Container>
          <Container textColor="gray-50" fontSize="textMd">
            {publishDate && (
              <>
                <Text>{publishDate}</Text>
                <Text
                  display="inline-block"
                  width="8px"
                  height="8px"
                  borderRadius="50%"
                  marginX={24}
                  backgroundColor="gray-50"
                />
                <Text>{readingTime} minute read</Text>
              </>
            )}
          </Container>
        </Flex>
        <Container borderRadius="12px" overflowX="hidden" overflowY="hidden">
          <Image image={featuredImage as ImageData} alt={title || ''} width="100%" />
        </Container>
      </Container>
    </Flex>
  );
};

export default BlogHero;
