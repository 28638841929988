import { PageProps, graphql } from 'gatsby';
import {
  DatoCmsComponentHeading,
  DatoCmsSeoField,
  DatoCmsTemplateBlog,
  DatoCmsTemplateBlogConnection,
} from 'graphqlTypes';

import Flex from 'quarks/Flex';

import Section from 'molecules/Section/Section';

import BlogDetail from 'components/Blog/BlogDetail';
import BlogHero from 'components/Blog/BlogHero';
import BlogSingleCard from 'components/Cards/BlogSingleCard/BlogSingleCard';
import ComponentHeading from 'components/Heading/Heading';

import Layout from 'layouts/Layout';
import LayoutSection from 'layouts/LayoutSection';

import type { FC } from 'react';

interface BlogDataType {
  blogData: DatoCmsTemplateBlog;
  mostRecentData: DatoCmsTemplateBlogConnection;
  cardHeading: DatoCmsComponentHeading;
}

const Blog: FC<PageProps<BlogDataType>> = ({
  data: { blogData, mostRecentData, cardHeading },
  location,
  pageContext,
}) => {
  console.warn(`${blogData.internalName} is missing seo`); // TODO: Fix pages that have broken SEO items

  return (
    <Layout
      seo={blogData.seo as DatoCmsSeoField}
      slug={location.pathname}
      domain={(pageContext as any)?.domain}
      lang={(pageContext as any)?.language}
    >
      <Section
        id="blog-hero"
        paddingTopDesktop="80"
        paddingTopTablet="80"
        paddingTopMobile="80"
        paddingBottomDesktop="56"
        paddingBottomTablet="40"
        paddingBottomMobile="40"
        isOverSection
        backgroundColor="secondaryGrayBlue-900"
      >
        <BlogHero {...blogData} />
      </Section>
      <Section
        id={blogData.internalName || blogData.id}
        paddingTopTablet="48"
        paddingTopMobile="48"
        paddingBottomTablet="48"
        paddingBottomMobile="48"
        isOverSection
        backgroundColor="secondaryGrayBlue-25"
      >
        <BlogDetail {...blogData} />
      </Section>
      <Section
        id="recent-blog-list"
        paddingTopDesktop="96"
        paddingTopTablet="72"
        paddingTopMobile="48"
        paddingBottomDesktop="96"
        paddingBottomTablet="72"
        paddingBottomMobile="48"
        isOverSection
        backgroundColor="secondaryGrayBlue-900"
      >
        <ComponentHeading {...cardHeading} isSubComponent marginBottom={64} />
        <Flex flexWrap="wrap" justifyContent="center" gap="32px" flexDirection="column" sm={{ flexDirection: 'row' }}>
          {mostRecentData?.edges?.slice(0, 3)?.map((blog, idx) => (
            <BlogSingleCard
              key={blog.node.id}
              {...blog.node}
              width="100%"
              flexGrow={1}
              flexBasis={0}
              isDark
              sm={{
                flex: '0 0 calc(50% - 16px)',
                width: 'calc(50% - 16px)',
                maxWidth: '380px',
                display: idx === 2 ? 'none' : 'flex',
              }}
              lg={{
                flex: '0 0 calc(33.33% - 22px)',
                width: 'calc(33.33% - 22px)',
                display: 'flex',
              }}
            />
          ))}
        </Flex>
      </Section>
      {blogData?.sections &&
        blogData.sections.map(
          (section, index) =>
            section && (
              <LayoutSection
                key={section.id}
                isLastSection={index === (blogData?.sections?.length || 1) - 1}
                {...section}
              />
            ),
        )}
    </Layout>
  );
};

export default Blog;

export const blogQuery = graphql`
  query templateBlogQuery($slug: String!, $domain: String!, $language: String!) {
    blogData: datoCmsTemplateBlog(
      slug: { eq: $slug }
      domains: { elemMatch: { domain: { eq: $domain } } }
      language: { eq: $language }
    ) {
      ...datoCmsTemplateBlog
    }
    mostRecentData: allDatoCmsTemplateBlog(
      filter: { domains: { elemMatch: { domain: { eq: $domain } } }, slug: { ne: $slug }, language: { eq: $language } }
      limit: 3
      sort: { fields: publishDate, order: DESC }
    ) {
      edges {
        node {
          ...datoCmsTemplateBlogList
        }
      }
    }
    cardHeading: datoCmsComponentHeading(originalId: { eq: "160086865" }) {
      ...datoCmsComponentHeading
    }
  }
`;
