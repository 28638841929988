// Quarks
import { FC } from 'react';

import color from 'atoms/colors/colors';

import Container from 'quarks/Container';
import Flex from 'quarks/Flex';
import Heading from 'quarks/Heading';
import type { FlexProps } from 'quarks/interpolations/flex';

import Icon from 'molecules/Icon/Icon';
import TextField from 'molecules/TextField/TextField';

import ComponentButton from 'components/ComponentButton/ComponentButton';

interface ComponentFormProps extends FlexProps {
  isBackgroundDark?: boolean;
}

const BlogSubscribeForm: FC<ComponentFormProps> = () => (
  <Flex
    flexDirection="column"
    borderRadius="16px"
    paddingAll={24}
    position="relative"
    textAlign="center"
    overflowX="hidden"
    overflowY="hidden"
    css={`
      background: linear-gradient(94.96deg, #0D273F 5.15%, #194664 104.64%);
      &::before {
        content: '';
        position: absolute;
        width: 470px;
        height: 470px;
        top: -50px;
        left: -50px
        background: radial-gradient(51.88% 51.88% at 54.84% 48.12%, rgba(53, 171, 225, 0.4) 0%, rgba(53, 171, 225, 0) 100%);
      }
    `}
  >
    <Container>
      <Icon iconName="mail-02" size={40} color={color.secondaryTurqoise[400]} />
    </Container>
    <Heading as="h4" textStyle="xs" fontWeight="semiBold" marginBottom={24} textColor="common-white">
      Sign up for our newsletter
    </Heading>
    <Flex flexDirection="column" gap="16px">
      <Flex flexDirection="column" width="100%" sm={{ flexGrow: 1, flexBasis: 0 }}>
        <TextField placeholder="Example@example.com" type="email" />
      </Flex>
      <Flex>
        <ComponentButton label="Subscribe" buttonType="fill" width="100%" color="secondary_turqoise_400" size="lg" />
      </Flex>
    </Flex>
  </Flex>
);

export default BlogSubscribeForm;
